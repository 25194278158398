import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  PrivateLayout,
  PublicLayout,
  ReservationPrintIntegration,
  Spinner,
} from "../components";
import {
  AboutUsIntegration,
  AccountIntegration,
  ActivitiesIntegration,
  ChangeAndCancellationPoliciesIntegration,
  EditProfileIntegration,
  ExtrasIntegration,
  HomeIntegration,
  LoginIntegration,
  Page403,
  Page404,
  PaymentErrorIntegration,
  PaymentIntegration,
  PaymentSuccessIntegration,
  ResetPasswordIntegration,
  SearchIntegration,
  SeoIntegration,
  TermsAndConditionsIntegration,
  TourIntegration,
  TravelersIntegration,
  TravelersSelfServiceIntegration,
} from "../pages";
import * as U from "../pages/u";
import { PrivateHostRoute } from "./PrivateHostRoute";
import { PrivateRoute } from "./PrivateRoute";
import { RedirectToTravelersSelfService } from "./RedirectToTravelersSelfService";

const pathBase = (path: string): string => `/:languageCode` + path;

export const Router = (): JSX.Element => (
  <Suspense fallback={<Spinner height="90vh" />}>
    <Routes>
      <Route
        path={pathBase("?")}
        element={
          <PublicLayout>
            <HomeIntegration />
          </PublicLayout>
        }
      />
      <Route path={pathBase("/login")} element={<LoginIntegration />} />
      <Route
        path={pathBase("/search")}
        element={
          <PublicLayout>
            <SearchIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/email/reset-password")}
        element={
          <PublicLayout>
            <ResetPasswordIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/help-center")}
        element={<Navigate to={"/"} replace />}
      />
      <Route
        path={pathBase("/about-us")}
        element={
          <PublicLayout>
            <AboutUsIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/set-pickup-location")}
        element={<RedirectToTravelersSelfService />}
      />
      <Route
        path={pathBase("/upload-travelers-passports")}
        element={<RedirectToTravelersSelfService />}
      />
      <Route
        path={pathBase("/travelers-self-service")}
        element={<TravelersSelfServiceIntegration />}
      />
      <Route
        path={pathBase("/a/*")}
        element={
          <PublicLayout>
            <ActivitiesIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/t/*")}
        element={
          <PublicLayout>
            <SeoIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/s/a/*")}
        element={
          <PublicLayout>
            <ActivitiesIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/s/t/*")}
        element={
          <PublicLayout>
            <SeoIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/s/tours/*")}
        element={
          <PublicLayout>
            <SeoIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/operators/:operatorId/tours/:tourId")}
        element={
          <PublicLayout>
            <TourIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase(
          "/operators/:operatorId/tours/:tourId/booking/travelers"
        )}
        element={
          <PublicLayout>
            <TravelersIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/operators/:operatorId/tours/:tourId/booking/extras")}
        element={
          <PublicLayout>
            <ExtrasIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/operators/:operatorId/tours/:tourId/booking/payment")}
        element={
          <PublicLayout>
            <PaymentIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase(
          "/operators/:operatorId/tours/:tourId/booking/payment/success"
        )}
        element={
          <PublicLayout>
            <PaymentSuccessIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase(
          "/operators/:operatorId/tours/:tourId/booking/payment/error"
        )}
        element={
          <PublicLayout>
            <PaymentErrorIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/operators/:operatorId/terms-and-conditions")}
        element={
          <PublicLayout>
            <TermsAndConditionsIntegration />
          </PublicLayout>
        }
      />
      <Route
        path={pathBase("/tours/:tourId/change-and-cancellation-policies")}
        element={
          <PublicLayout>
            <ChangeAndCancellationPoliciesIntegration />
          </PublicLayout>
        }
      />
      <Route path={pathBase("/u")}>
        <Route
          path="operators/:operatorId"
          element={
            <PrivateRoute aclId="operatorsPage">
              <PrivateLayout>
                <U.OperatorIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/tours"
          element={
            <PrivateRoute aclId="toursPage">
              <PrivateLayout>
                <U.ToursIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/tours/:tourId"
          element={
            <PrivateRoute aclId="toursPage">
              <PrivateLayout showOperatorSelector={false}>
                <U.TourIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/reservations"
          element={
            <PrivateRoute aclId="reservationsPage">
              <PrivateLayout wrapper={false}>
                <U.ReservationsByOperatorIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/manage-tours-availability"
          element={
            <PrivateRoute aclId="manageToursAvailabilityPage">
              <PrivateLayout wrapper={false}>
                <U.ManageToursAvailability />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/travelers"
          element={
            <PrivateRoute aclId="travelersPage">
              <PrivateLayout wrapper={false}>
                <U.TravelersIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/travelers/print"
          element={
            <PrivateRoute aclId="printTravelersPage">
              <U.TravelersPrintIntegration />
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/reservations-summary"
          element={
            <PrivateRoute aclId="reservationsSummaryPage">
              <PrivateLayout wrapper={false}>
                <U.ReservationsSummaryIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/reservations-summary/print"
          element={
            <PrivateRoute aclId="printReservationsSummaryPage">
              <U.PrintReservationsSummaryIntegration />
            </PrivateRoute>
          }
        />
        <Route
          path="manage-operators-availability"
          element={
            <PrivateRoute aclId="manageOperatorsAvailabilityPage">
              <PrivateLayout wrapper={false}>
                <U.ManageOperatorsAvailabilityIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="default-roles-acls"
          element={
            <PrivateRoute aclId="defaultRolesAclsPage">
              <PrivateLayout>
                <U.DefaultRolesAclsIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="default-roles-acls/:roleAclsId"
          element={
            <PrivateRoute aclId="defaultRolesAclsPage">
              <PrivateLayout>
                <U.RoleAclIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators"
          element={
            <PrivateRoute aclId="operatorsPage">
              <PrivateLayout>
                <U.OperatorsIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="countries"
          element={
            <PrivateRoute aclId="countriesPage">
              <PrivateLayout>
                <U.CountriesIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="countries/:countryId"
          element={
            <PrivateRoute aclId="countriesPage">
              <PrivateLayout>
                <U.CountryIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="countries/:countryId/cities"
          element={
            <PrivateRoute aclId="citiesPage">
              <PrivateLayout>
                <U.CitiesIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="countries/:countryId/cities/:cityId"
          element={
            <PrivateRoute aclId="citiesPage">
              <PrivateLayout>
                <U.CityIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="reservations"
          element={
            <PrivateRoute aclId="reservationsPage">
              <PrivateLayout wrapper={false}>
                <U.ReservationsIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="reservations/:reservationId/print"
          element={
            <PrivateRoute aclId="reservationsPage">
              <ReservationPrintIntegration />
            </PrivateRoute>
          }
        />
        <Route
          path="pickup-locations"
          element={
            <PrivateRoute aclId="pickupLocationsPage">
              <PrivateLayout>
                <U.PickupLocationsIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="hosts"
          element={
            <PrivateRoute aclId="hostsPage">
              <PrivateLayout>
                <U.HostsIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="hosts/:hostId"
          element={
            <PrivateRoute aclId="hostsPage">
              <PrivateLayout>
                <U.HostIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="agencies"
          element={
            <PrivateRoute aclId="agenciesPage">
              <PrivateLayout>
                <U.AgenciesIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="agencies/:agencyId"
          element={
            <PrivateRoute aclId="agenciesPage">
              <PrivateLayout>
                <U.AgencyIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="agencies/:agencyId/offices"
          element={
            <PrivateRoute aclId="officesPage">
              <PrivateLayout>
                <U.OfficesIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="agencies/:agencyId/offices/:officeId"
          element={
            <PrivateRoute aclId="officesPage">
              <PrivateLayout>
                <U.OfficeIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="discounts"
          element={
            <PrivateRoute aclId="discountsPage">
              <PrivateLayout>
                <U.DiscountsIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="discounts/:discountId"
          element={
            <PrivateRoute aclId="discountsPage">
              <PrivateLayout>
                <U.DiscountIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="users"
          element={
            <PrivateRoute aclId="usersPage">
              <PrivateLayout>
                <U.UsersIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="users/:userId"
          element={
            <PrivateRoute aclId="usersPage">
              <PrivateLayout>
                <U.UserIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="activities"
          element={
            <PrivateRoute aclId="activitiesPage">
              <PrivateLayout>
                <U.ActivitiesIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="activities/:activityId"
          element={
            <PrivateRoute aclId="activitiesPage">
              <PrivateLayout>
                <U.ActivityIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="categories"
          element={
            <PrivateRoute aclId="categoriesPage">
              <PrivateLayout>
                <U.CategoriesIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="categories/:categoryId"
          element={
            <PrivateRoute aclId="categoriesPage">
              <PrivateLayout>
                <U.CategoryIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="operators/:operatorId/options-availability"
          element={
            <PrivateRoute aclId="optionsAvailabilityPage">
              <PrivateLayout wrapper={false}>
                <U.OptionsAvailabilityIntegration />
              </PrivateLayout>
            </PrivateRoute>
          }
        />
      </Route>
      <Route element={<PrivateHostRoute />}>
        <Route
          path={pathBase("/account")}
          element={
            <PublicLayout>
              <AccountIntegration />
            </PublicLayout>
          }
        />
        <Route
          path={pathBase("/account/edit/:hostId")}
          element={
            <PublicLayout>
              <EditProfileIntegration />
            </PublicLayout>
          }
        />
      </Route>
      <Route path="403" element={<Page403 />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  </Suspense>
);
