import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { isEqual, orderBy, words } from "lodash";
import { useAcl } from "../hooks";
import { useOpenApi } from "../api";
import { SearchApi, SearchResult } from "../openapi";
import MiniSearch, { SearchResult as SearchResultMiniSearch } from "minisearch";
import { useLanguage } from "./LanguageProvider";
import { useAuthentication } from "./AuthenticationProvider";
import { normalizeText } from "../utils";

interface Context {
  getById: (id: string) => SearchResult | undefined;
  searchIndex: SearchResult[];
  search: (query: string) => SearchResultMiniSearch[];
  loading: boolean;
}

const SearchResultsDataContext = createContext<Context>({
  getById: () => undefined,
  searchIndex: [],
  search: () => [],
  loading: true,
});

interface Props {
  children: JSX.Element;
}

const MINI_SEARCH = new MiniSearch<SearchResult>({
  fields: ["title", "tags", "countryName", "cityName"],
  storeFields: [
    "title",
    "operatorId",
    "photoName",
    "minPrice",
    "seoPath",
    "currencyCode",
    "hidePublic",
    "isActivity",
    "countryName",
    "cityName",
  ],
  processTerm: (term) => normalizeText(term),
});

export const SearchResultsProvider = ({ children }: Props): JSX.Element => {
  const { currentLanguageCode } = useLanguage();
  const { authUser } = useAuthentication();
  const { aclCheck } = useAcl();
  const [initializingMiniSearch, setInitializingMiniSearch] = useState(true);
  const {
    data: searchIndex = [],
    loading: loadingSearchIndex = true,
  } = useOpenApi(SearchApi, "getSearchIndex", {
    initialParams: [{ languageCode: currentLanguageCode }],
  });

  useEffect(() => {
    if (!loadingSearchIndex) {
      MINI_SEARCH.removeAll();
      MINI_SEARCH.addAll(searchIndex);
      setInitializingMiniSearch(false);
    }
  }, [loadingSearchIndex]);

  const search = useCallback(
    (query: string) => {
      const filterSearchResult = (result: SearchResultMiniSearch) =>
        (authUser
          ? aclCheck("filterToursByOperator")
            ? authUser.operatorsIds.includes(result.operatorId)
            : true
          : !result.hidePublic) &&
        isEqual(
          result.queryTerms,
          words(normalizeText(query)).map((w) => w)
        );

      const searchResults = MINI_SEARCH.search(query, {
        prefix: true,
        filter: filterSearchResult,
      });

      return orderBy(searchResults, ["isActivity", "score"], ["asc", "desc"]);
    },
    [searchIndex.length, loadingSearchIndex]
  );

  const getById = useCallback(
    (id: string) => {
      return searchIndex.find((search) => search.id === id);
    },
    [searchIndex.length]
  );

  return (
    <SearchResultsDataContext.Provider
      value={{
        getById,
        searchIndex,
        search,
        loading: initializingMiniSearch,
      }}
    >
      {children}
    </SearchResultsDataContext.Provider>
  );
};

export const useSearchResultsData = (): Context =>
  useContext(SearchResultsDataContext);
