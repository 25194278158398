/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentLink
 */
export interface PaymentLink {
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    expirationDate: string;
}

/**
 * Check if a given object implements the PaymentLink interface.
 */
export function instanceOfPaymentLink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "expirationDate" in value;

    return isInstance;
}

export function PaymentLinkFromJSON(json: any): PaymentLink {
    return PaymentLinkFromJSONTyped(json, false);
}

export function PaymentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'expirationDate': json['expirationDate'],
    };
}

export function PaymentLinkToJSON(value?: PaymentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'expirationDate': value.expirationDate,
    };
}

