import React, { useState } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import {
  useLanguage,
  useDomainProvider,
  useAuthentication,
  useUTM,
  useOffice,
} from "../../../providers";
import { useDevice, useI18n } from "../../../hooks";
import PublicHeader, { PublicHeaderProps } from "./Public.Header";
import PublicMenu from "./Public.Menu";
import PublicFooter from "./Public.Footer";
import { useNavigate } from "react-router-dom";
import { pathnameWithLanguage } from "../../../utils";

interface Props {
  children: JSX.Element;
}

export const PublicLayout = ({ children }: Props): JSX.Element => {
  const translate = useI18n();
  const { onChangeLanguage, currentLanguageCode } = useLanguage();
  const { domain, subdomain } = useDomainProvider();
  const { isMobile } = useDevice();
  const { authUser, logout } = useAuthentication();
  const { utm } = useUTM();
  const navigate = useNavigate();
  const { currentOffice } = useOffice();

  const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false);

  const onSetIsActiveMenu = () => setIsActiveMenu(!isActiveMenu);

  const onClickLogout: PublicHeaderProps["onClickLogout"] = async () =>
    logout();

  const onClickLogo: PublicHeaderProps["onClickLogo"] = (path) =>
    navigate(pathnameWithLanguage(currentLanguageCode, path));

  const onClickLogin: PublicHeaderProps["onClickLogin"] = () =>
    navigate(pathnameWithLanguage(currentLanguageCode, "/login"));

  return (
    <LayoutStyled>
      <PublicHeader
        domain={domain}
        subdomain={subdomain}
        translate={translate}
        isMobile={isMobile}
        currentLanguageCode={currentLanguageCode}
        onChangeLanguage={onChangeLanguage}
        onSetIsActiveMenu={onSetIsActiveMenu}
        authUser={authUser}
        utm={utm}
        currentOffice={currentOffice}
        onClickLogout={onClickLogout}
        onClickLogo={onClickLogo}
        onClickLogin={onClickLogin}
      />
      <LayoutBodyStyled>{children}</LayoutBodyStyled>
      <PublicMenu
        domain={domain}
        subdomain={subdomain}
        translate={translate}
        isActiveMenu={isActiveMenu}
        currentLanguageCode={currentLanguageCode}
        onChangeLanguage={onChangeLanguage}
        onSetIsActiveMenu={onSetIsActiveMenu}
      />
      <PublicFooter
        domain={domain}
        subdomain={subdomain}
        translate={translate}
        currentLanguageCode={currentLanguageCode}
      />
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};

  .ant-layout-header {
    padding: 0;
    box-sizing: border-box;
    line-height: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const LayoutBodyStyled = styled(Layout.Content)`
  padding: 0;
  overflow: initial;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
`;
