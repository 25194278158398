import { useParams } from "react-router";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";

export const RedirectToTravelersSelfService = (): React.ReactElement => {
  const { languageCode } = useParams();
  const location = useLocation();

  return (
    <Navigate
      to={`/${languageCode}/travelers-self-service${location.search}`}
      replace
    />
  );
};
