/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Gender } from './Gender';
import {
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';

/**
 * 
 * @export
 * @interface TravelerPassport
 */
export interface TravelerPassport {
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    id: string;
    /**
     * 
     * @type {Image}
     * @memberof TravelerPassport
     */
    passportImage: Image;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    issuingCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    expirationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassport
     */
    nationality?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof TravelerPassport
     */
    gender?: Gender | null;
}

/**
 * Check if a given object implements the TravelerPassport interface.
 */
export function instanceOfTravelerPassport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "passportImage" in value;
    isInstance = isInstance && "number" in value;

    return isInstance;
}

export function TravelerPassportFromJSON(json: any): TravelerPassport {
    return TravelerPassportFromJSONTyped(json, false);
}

export function TravelerPassportFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelerPassport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'passportImage': ImageFromJSON(json['passportImage']),
        'number': json['number'],
        'issuingCountryCode': !exists(json, 'issuingCountryCode') ? undefined : json['issuingCountryCode'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : json['expirationDate'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
    };
}

export function TravelerPassportToJSON(value?: TravelerPassport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'passportImage': ImageToJSON(value.passportImage),
        'number': value.number,
        'issuingCountryCode': value.issuingCountryCode,
        'expirationDate': value.expirationDate,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'birthDate': value.birthDate,
        'nationality': value.nationality,
        'gender': GenderToJSON(value.gender),
    };
}

