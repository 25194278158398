/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetIsBeforeCutOff
 */
export interface GetIsBeforeCutOff {
    /**
     * 
     * @type {boolean}
     * @memberof GetIsBeforeCutOff
     */
    cutOffSuccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIsBeforeCutOff
     */
    rescheduleSuccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIsBeforeCutOff
     */
    pickupLocationSuccess: boolean;
}

/**
 * Check if a given object implements the GetIsBeforeCutOff interface.
 */
export function instanceOfGetIsBeforeCutOff(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cutOffSuccess" in value;
    isInstance = isInstance && "rescheduleSuccess" in value;
    isInstance = isInstance && "pickupLocationSuccess" in value;

    return isInstance;
}

export function GetIsBeforeCutOffFromJSON(json: any): GetIsBeforeCutOff {
    return GetIsBeforeCutOffFromJSONTyped(json, false);
}

export function GetIsBeforeCutOffFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIsBeforeCutOff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cutOffSuccess': json['cutOffSuccess'],
        'rescheduleSuccess': json['rescheduleSuccess'],
        'pickupLocationSuccess': json['pickupLocationSuccess'],
    };
}

export function GetIsBeforeCutOffToJSON(value?: GetIsBeforeCutOff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cutOffSuccess': value.cutOffSuccess,
        'rescheduleSuccess': value.rescheduleSuccess,
        'pickupLocationSuccess': value.pickupLocationSuccess,
    };
}

