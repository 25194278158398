import { uniq } from "lodash";

export const normalizeTexts = (texts: string[]): string[] =>
  uniq(texts).map((text) => normalizeText(text));

export const normalizeTextForUrl = (text: string): string =>
  normalizeText(text).replace(/\s+/g, "-");

export const normalizeText = (text: string): string =>
  text
    .trim()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();

export const normalizeTextAndRemoveSpaces = (text: string): string =>
  normalizeText(text).replace(/\s+/g, "");
